(function($) {

	$.noty.layouts.top = {
		name: 'top',
		options: { // overrides options

		},
		container: {
			object: '<ul id="noty_top_layout_container" />',
			selector: 'ul#noty_top_layout_container',
			style: function() {
				$(this).css({
					top: 80,
					right: 20,
					position: 'fixed',
					width: '410px',
					height: 'auto',
					margin: 0,
					padding: 0,
					listStyleType: 'none',
					zIndex: 10000000
				});

				if (window.innerWidth < 600) {
					$(this).css({
						right: 5
					});
				}
			}
		},
		parent: {
			object: '<li />',
			selector: 'li',
			css: {
				marginBottom: '15px'
			}
		},
		css: {
			display: 'none',
			width: '410px'
		},
		addClass: ''
	};

})(jQuery);
