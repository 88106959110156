(($) ->
  # We need this to make jquery ui work because we have excluded animation
  # in jQuery
  $.fn.stop = -> @

  class Spinner
    constructor: (@element, @settings) ->
      if @settings.existing
        @spinner = @element.find('i')
      else
        @create()
      @start() if @settings.autoStart

    # Create the spinner element
    create: ->
      @spinner = $ "<#{@settings.tagName}>"

      # Add requested size classes
      if @settings.size
        @spinner.addClass "fa-#{@settings.size}"

      # Add the icon
      @spinner.addClass "fa fa-#{@settings.icon}"

      # Are we trying to spin a button?
      return @createButton() if @element.hasClass 'btn'

      # Set a default insert method
      unless @settings.insert
        @settings.insert = 'prepend'

      # Create a container
      @container = $('<div>').css(
        position: 'relative'
        width: '100%'
        textAlign: 'center'
      ).append @spinner

      # To inline a spinner set top: null
      return @insert() if @settings.top is null

      # Extra stuff if we are positioning the spinner vertically
      @createVerticallyAligned()

    # Extra properties for vertically positioned spinners
    createVerticallyAligned: ->
      styles =
        position: 'absolute'
        top: @settings.top
        zIndex: @settings.zIndex

      # Insert the spinner to the DOM
      @insert()

      styles.left = "#{(@container.width() - @spinner.width()) / 2}px"

      #Do we want it in the middle ie "50% - spinner height"?
      if @settings.top is 'auto'
        outer = parseInt @element.outerHeight()/2
        inner = parseInt @spinner.outerHeight()/2
        styles.top = "#{outer-inner}px"

      @spinner.css styles

    createButton: ->
      # Default spinners to be appended to buttons
      unless @settings.insert
        @settings.insert = 'append'

      # Add some margin to left or right if we prepend or append
      if @settings.insert is 'prepend'
        @spinner.css 'margin-right', '5px'
      if @settings.insert is 'append'
        @spinner.css 'margin-left', '5px'

      # Insert the spinner to the DOM
      @insert()

    # Insert the spinner to the DOM
    insert: ->
      if @spinner
        @element[@settings.insert] @container or @spinner

    start: ->
      if @element.hasClass 'btn'
        @element.prop 'disabled', true
      else
        @element.children().not(@container).css 'opacity', 0.6
      @spinner.addClass 'fa-spin'

    stop: ->
      if @element.hasClass 'btn'
        @element.prop 'disabled', false
      else
        @element.children().not(@spinner).css 'opacity', 1
      @spinner.removeClass 'fa-spin'

    toggle: ->
      if @spinner.hasClass 'fa-spin'
        @stop()
      else
        @start()

    # Stop the spinner and delete eventual created elements
    destroy: ->
      #return
      @stop()
      @spinner.remove()
      @container?.remove()
      delete @spinner
      delete @container
      delete @settings
      delete @element

  $.spin =
    defaults:
      autoStart: yes
      icon: 'spinner'
      zIndex: '10'
      top: 'auto'
      tagName: 'i'

  $.fn.spin = (settings = {}) ->
    @each ->
      $this = $(@)
      data = $this.data()

      if data.spinner
        switch settings
          when 'destroy', false
            data.spinner.destroy()
            delete data.spinner
          when 'start' then data.spinner.start()
          when 'stop' then data.spinner.stop()
          else data.spinner.toggle()
      else if settings isnt false and typeof settings isnt 'string'
        settings = $.extend {}, $.spin.defaults, settings
        data.spinner = new Spinner $this, settings
)(jQuery)
